import React from 'react'
import styles from "./ChatModal.module.scss"
import moment from 'moment'
import { getFileIco } from '../../../utils/helpers'
import { BACKEND_API_URL } from '../../../utils/constants'

interface Props {
    message: any
}

const ChatMessage:React.FC<Props> = ({message}) => {
    return (
        <div className={`${styles.chatMessage} ${message.itsMe ? styles.chatMessage_my : ""} `}>
            {!message.itsMe && 
                <div className={`${styles.chatMessage__avatar}`}>
                    <img src={"https://dev.staq.ru/api/v1/public/photo/preview/9cd2d351-1b19-407c-95fc-bdabb0def8cf"} alt="" />
                </div>
            }
            <div className={`${styles.chatMessage__block}`}>
                {!message.itsMe && <div className={`${styles.chatMessage__name}`}>{message.userName}</div>}
                <div className={`${styles.chatMessage__text}`}>{message.text}</div>
                {/* Изображения */}
                {!!message.photos?.length && 
                    <div className={`${styles.chatMessage__images}`}>{message.photos?.map((el:string, kk:number)=><img key={kk} src={el} alt='' />)}</div>
                }
                {/* Файлы */}
                {/* {!!message.photos?.length &&  */}
                    <div className={`${styles.chatMessage__files}`}>
                        {message.attachments_info?.map((el:any, kk:number)=>{
                            const fileType = el?.filename?.substring(el?.filename?.lastIndexOf('.') + 1, el?.filename?.length)
                            const [iconVisible, iconSrc] = getFileIco(fileType)
                            return(
                                <a href={`${BACKEND_API_URL}/public/attachment/${el?.id}`} className={`${styles.orderModal__file}`} target="_blank" rel="noreferrer" key={el?.id || kk}>
                                    {iconVisible ? (
                                        <img src={`${iconSrc}` ?? ''} alt=""/>) 
                                        : (<></>)
                                    }
                                    <div className={styles.orderModal__fileInfo}>
                                        <div className={styles.orderModal__fileName}>
                                            {el.filename}
                                        </div>
                                        <div className={styles.orderModal__fileSize}>
                                            {`${Number(el?.size / 1000000).toFixed(2)}Mb`}
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </div>
                {/* } */}
                <div className={`${styles.chatMessage__time}`}>{moment(message.date).format("HH:mm")}</div>
                
            </div>
            
        </div>
    )
}

export default ChatMessage