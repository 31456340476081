import React, { useState } from 'react'
import styles from "./ChatModal.module.scss"
import Modal from '../../Modal/Modal'
import TitleBack from '../../TitleBack/TitleBack'
import ReactTextareaAutosize from "react-textarea-autosize"
import { useTranslation } from 'react-i18next'
import ChatMessage from './ChatMessage'
import moment from 'moment'
import FileDropzone from '../../Assets/FileDropzone/FileDropzone'
import { useFormik } from 'formik'

interface Props {
    openChat: boolean
    setOpenChat: any
    orderInfo: any
}

const testChatMessages = [
    {
        userName: "Жуков Жук",
        text: "Добрый день, просьба уточнить проблему. У вас принтер включен в розетку?",
        created_at: 1711346514,
        readed_at: 1711530506,
        itsMe: false,
        attachments: [],
        attachments_info: [],
        photos: [],
        photos_info: [],
        id: "fdgdgdfgdgdgdfg"
    },
    {
        userName: "",
        text: "Добрый день! Естественно принтер включен в розетку",
        created_at: 1711346514,
        readed_at: 1711530506,
        itsMe: true,
        attachments: [],
        attachments_info: [],
        photos: [],
        photos_info: [],
        id: "fdgdgdfgdgdgdfg123"
    },
    {
        userName: "Жуков Жук",
        text: "",
        created_at: 1711346514,
        readed_at: 1711530506,
        itsMe: false,
        attachments: [],
        attachments_info: [],
        photos: ["https://sun9-23.userapi.com/impf/c636420/v636420339/2f8/mEInMCYFfUI.jpg?size=640x512&quality=96&sign=34a9d640a547d663a0f0e55ef2aa4f40&c_uniq_tag=XAjjwBc58g9NQ16xv9-345VibwQmIFlYxdNvG9hr-DY&type=album"],
        photos_info: [],
        id: "fdgdgdfgdgdgdfg321"
    },
    {
        userName: "Жуков Жук",
        text: "Фоточки",
        created_at: 1711346514,
        readed_at: 1711530506,
        itsMe: false,
        attachments: [],
        attachments_info: [],
        photos: ["https://klike.net/uploads/posts/2023-02/1675842942_3-315.jpg", "https://sun9-12.userapi.com/impf/c845416/v845416779/186a4/pUZmFeIg39o.jpg?size=1280x720&quality=96&sign=2ea05b2e91dc6dd340826edf4d6ae94f&c_uniq_tag=tTHNdswzK82zVQQ9E7Q-9fiMLKpqyzLVkVa3wcvQ_LM&type=album"],
        photos_info: [],
        id: "fdgdgdfgdgdgdfg32111"
    },
    {
        userName: "Жуков Жук",
        text: "Файлик",
        created_at: 1711346514,
        readed_at: 1711530506,
        itsMe: false,
        attachments: ["e938f54b-cc78-473c-918f-1fd51021b868"],
        attachments_info: [{
            createdAt: 1711632873,
            filename: "dfgdgfdg.pdf",
            id: "e938f54b-cc78-473c-918f-1fd51021b868",
            size: 790608,
        }],
        photos: [],
        photos_info: [],
        id: "fdgdgdfgdgdgdfg32111333"
    },
]

const ChatModal:React.FC<Props> = ({openChat, setOpenChat, orderInfo}) => {
    const { t } = useTranslation("translation", { keyPrefix: `interface` })

    const formik:any = useFormik({
        initialValues: {
            text: "",
            attachments: [],
            attachments_info: [],
        },
        onSubmit(values, formikHelpers) {
            console.log(values);
        },
    })
    
    return (
        <div className={styles.chatModal__wp}>
            <Modal open={openChat} setOpen={setOpenChat}>
                <div className={styles.chatModal}>
                    <div className={styles.chatModal__head}>
                        <TitleBack title={orderInfo?.name} onClick={()=>setOpenChat(false)} noLinkBack/>
                    </div>
                    <div className={styles.chatModal__main}>
                        <img className={styles.chatModal__mainBack} src={"/img/chat_bg.png"}/>
                        <div className={styles.chatModal__mainList}>
                            <div className={styles.chatModal__chatDate}>{t("today")}</div>  
                            <div className={styles.chatModal__chatDate}>{t("yesterday")}</div>
                            <div className={styles.chatModal__newMessage}>
                                <span>{t("newMessage")}</span>
                            </div>
                            <div className={styles.chatModal__chatDate}>{moment(1711346514).format("DD MMMM YYYY")}</div>
                            {testChatMessages.map((el:any)=> {
                                return (
                                    <ChatMessage
                                        message={el} 
                                        key={el.id}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className={styles.chatModal__bottom}>
                        <label className={styles.chatModal__append}>
                            <img src="/img/clip.svg" alt="" />
                            <FileDropzone name={"attachments"} setFieldValue={formik.setFieldValue} values={formik.values} />
                        </label>
                        <ReactTextareaAutosize
                            minRows={1}
                            maxRows={3}
                            placeholder={t("enterMessage")}
                            onChange={(e) => formik.setFieldValue("text", e.target.value)}
                            value={formik.values.text}
                            className={styles.chatModal__input}
                        />
                        <button className={styles.chatModal__submit}>
                            <img src="/img/send.svg" alt="" />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ChatModal