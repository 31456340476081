import React from 'react'
import styles from "./FileDropzone.module.scss"
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getFileIco } from '../../../utils/helpers';

interface Props {
    name: string,
    setFieldValue: any
    values: any
    noFileVisible?: boolean
}

const FileDropzone:React.FC<Props> = ({name, setFieldValue, values, noFileVisible}) => {
    const {getRootProps, getInputProps, inputRef}:any = useDropzone({
        onDrop: (acceptedFiles) => {
            setFieldValue(`${inputRef.current.name}`, [
                ...values[inputRef.current.name],
                ...acceptedFiles.map((file: any) =>
                    Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    }),
                ),
            ])
        },
    });
    const {t} = useTranslation("translation", { keyPrefix: `interface` })

    const files = values[name]?.map((el: any, kk:number) => {        
        const fileType = el?.name?.substring(el?.name?.lastIndexOf('.') + 1, el?.name?.length)
        const [iconVisible, iconSrc] = getFileIco(fileType)
        return(
            <div className={`${styles.orderModal__file}`}  key={el?.id || kk}>
            {iconVisible ? (
                <img src={`${iconSrc}` ?? ''} alt=""/>) 
                : (<></>)
            }
            <div className={styles.orderModal__fileInfo}>
                <div className={styles.orderModal__fileName}>
                    {el.name}
                </div>
                <div className={styles.orderModal__fileSize}>
                    {`${Number(el?.size / 1000000).toFixed(2)}Mb ● ${moment(el?.lastModified).format(
                    `DD MMM YYYY ${t('in')} HH:mm`
                    )}`}
                </div>
            </div>
            <button
                type="button"
                className={`${styles.fileDropzone__delete}`}
                onClick={() => {
                    setFieldValue(
                        name,
                        values[name].filter((ell: any) => ell.path !== el.path),
                    )
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <circle cx="7.99967" cy="7.99992" r="6.66667" stroke="#ABB8C8" strokeWidth="1.4"/>
                    <path d="M5 8H11" stroke="#ABB8C8" strokeWidth="1.4" strokeLinecap="round"/>
                </svg>
            </button>
        </div>)
    })    

    return (
        <>
            <div
                {...getRootProps()}
                className={`fileDropzone ${styles.fileDropzone__append}`}
            >
                <input {...getInputProps()} name={name} />
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.99967 5.66659H5.66634V8.99992C5.66634 9.36659 5.36634 9.66659 4.99967 9.66659C4.63301 9.66659 4.33301 9.36659 4.33301 8.99992V5.66659H0.999674C0.633008 5.66659 0.333008 5.36659 0.333008 4.99992C0.333008 4.63325 0.633008 4.33325 0.999674 4.33325H4.33301V0.999919C4.33301 0.633252 4.63301 0.333252 4.99967 0.333252C5.36634 0.333252 5.66634 0.633252 5.66634 0.999919V4.33325H8.99967C9.36634 4.33325 9.66634 4.63325 9.66634 4.99992C9.66634 5.36659 9.36634 5.66659 8.99967 5.66659Z" fill="#1D8AE2">
                    </path>
                </svg>
                {t("selectFile")}
            </div>
            {noFileVisible ? files : <></>}
        </>
    )
}

export default FileDropzone